<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid" /> {{ caption }}
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        striped
        :fields="fields"
        :items="challenges"
        :items-per-page="5"
        :sorter="{ resetable: true }"
        :items-per-page-select="{ label: 'Retos por página:',
                                  values: [
                                    5,
                                    10,
                                    25,
                                    50,
                                    100,
                                    250,
                                    500
                                  ]
        }"
        :table-filter="{label: 'Filtro:', placeholder: 'Comience a escribir...'}"
        :no-items-view="{noResults: 'No se han encontrado datos', noItems: 'No se han encontrado datos que cumplan las características indicadas'}"
        pagination
      >
        <template #id="{ item }">
          <td>
            {{ item.challenge_id }}
          </td>
        </template>

        <template #challenge_title="{ item }">
          <td>
            {{ item.challenge_title }}
          </td>
        </template>

        <template #acciones="{ item }">
          <td>
            
            <CButton
              color="danger"
              shape="pill"
              size="sm"
              @click="deleteButton(item)"
            >
              Eliminar
            </CButton>

            <CModal
              id="modalElim"
              title="Eliminar reto"
              color="principal"
              :show.sync="deleteModal"
            >
              <p class="text-muted">
                ¿Está seguro de que desea eliminar el reto: 
                "{{ challenge.challenge_title }}" ? Esta acción no se puede deshacer.
              </p>
              <div slot="footer">
                <CButton
                  color="primary"
                  style="margin-right: 1em;"
                  @click="deleteChallenge(challenge.challenge_id)"
                >
                  Aceptar
                </CButton>
                <CButton
                  color="danger"
                  @click="deleteModal = false"
                >
                  Volver
                </CButton>
              </div>
            </CModal>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import config from "@/config";
import {getChallengesData, checkUser} from '../utils/utils';
export default {
  name: "TablaRetos",
  props: {
    fields: {
      type: Array,
      default() {
        return [
          {
            key: "challenge_title",
            label: "Nombre del reto"
          },
          {
            key: "challenge_description",
            label: "Descripción del reto"
          },
          {
            key: "acciones",
            sorter: false,
            filter: false
          }
        ];
      },
    },
    caption: {
      type: String,
      default: "Table",
    }
  },
  data() {
    return {
      deleteModal: false,
      challenge: {},
      challenges: [],
    }
  },
  created() {
    if (!checkUser()) return;
    this.receiveData();
  },
  methods: {
    receiveData(){
      this.getChallenges();
    },
    getChallenges() {    
      let data = getChallengesData();
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        let data = [];
        result.challenges.forEach(challenge => {
          let element = {};
          element.challenge_id = challenge.challenge_id;
          element.challenge_title = challenge.challenge_title;
          element.challenge_description = challenge.challenge_description;
          element.challenge_type = challenge.challenge_type;
          data.push(element);
        });
        this.challenges=data;
      });
    },
    /**
     * Este es el evento que se ejecuta al hacer click en el boton de eliminar de la tabla acciones
     * Eso hace que se abra el modal
     * 
     * @param {object} reto el reto sobre el que se ha llamado al evento
     */
    deleteButton(challenge) {
      this.challenge = challenge;
      this.deleteModal = true;
    },
    /**
     * Este metodo eliminará el reto, mandando por la URL el id
     * 
     * @param {number} id - El id del reto a eliminar
     */
    deleteChallenge(id) {
      const token = localStorage.token;
      axios.delete(
        config.BACK_IP + config.BACK_IP_RETOS,
        {
          headers: {
            token: token,
          },
          data: {
            challenge_id: id
          }
        }
      )
      .then(() => {
        this.getChallenges();
      })
      .catch((error) => {
        if(error.response) {
          if(error.response.status === 401) {
            localStorage.removeItem("token");
            window.location = "#/pages/login";
          }
          if (error.response.status == 404) {
            window.location = "#/pages/404";
          }
          if (error.response.status == 500) {
            window.location = "#/500";
          }
        }
      });
      this.deleteModal = false;
    },
  }
}
</script>