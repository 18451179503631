<template>
  <div>
    <CRow>
      <CCol
        lg="12"
        sm="1"
      >

        <CTableWrapper>
          <template #header>
            <div class="d-flex justify-content-between">
              Retos
              <div class="content-box">
                <CButton
                  class="btn btn-add"
                  style="margin-right:1em"
                  size="sm"
                  @click="addChallenge()"
                >
                  Añadir reto
                </CButton>
               <CButton
                  color="info"
                  class="btn btn-info"
                  size="sm"
                  @click="seeChallengeTypes()"
                >
                  Tipos de reto
                </CButton>
              </div>
            </div>
          </template>
        </CTableWrapper>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CTableWrapper from "../../components/ChallengesTable";
import {checkUser} from '../../utils/utils';
import "core-js/stable";
import "regenerator-runtime/runtime";
export default {
  name: "ChallengeTable",
  components: { CTableWrapper },
  created() {
    if (!checkUser()) return;
  },
  methods: {
    addChallenge() {
      this.$router.push("/challenge/create/");
    },
    seeChallengeTypes(){
      this.$router.push("/challenge/types/");
    },
  },
};
</script>